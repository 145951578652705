<template>
<div class="row">
  <div v-if="left" :class="leftPadding"> </div>
  <div :class="middle" ref="phone">
    <div v-if="isTitleShowing" class="rc-font-small rc-text-key">
    {{ Catelog.get(placeholder) }}
    </div>
    <div v-else class="rc-font-small">
      <div v-if="placeholder != null" > 
        <br> 
      </div>
    </div>
    <input class="rc-input-field rc-input-width rc-text-value"
      :placeholder="placeholderText()" 
      :disable="disabled"
      type="tel" 
      v-model="value"
      v-on:keyup.enter="enter"
      v-on:keyup="withValue" 
      style="height: 30px; padding-left: 20px; padding-right: 20px;"
      />
    <div v-if="hint" class="rc-font-small">{{ hint }}</div>
    <div v-if="required" class="rc-font-small rc-background-required">A value for {{ Catelog.get(placeholder) }} is required</div>

  </div>
  <div v-if="right" :class="rightPadding"> </div>
</div>  
</template>

<script>
import ContentUtils from '@/utils/ContentUtils';
import Catelog from '@/domain/session/CanadianEnglish.js';

export default {
  name: 'key-phone',
  components: {
  },
  props: {
    title:  String,
    hint:   String,
    defaultValue: String,
    placeholder:  String,
    left:   {type: Number, default: 0},
    right:  {type: Number, default: 0},
    disabled:   {type: Boolean, default: false},
    mandatory:  {type: Boolean, default: false},
    required:   {type: Boolean, default: false},
  },
  data() {
    return {
      value: this.defaultValue ? ContentUtils.unescape(this.defaultValue) : this.defaultValue,
      isTitleShowing: false,
      Catelog: new Catelog(),
    };
  },
  watch: {
    value() {
      this.validate();
    },
    required() {
      if (this.required) {
        this.$refs.phone.scrollIntoView();
      }
    },
  },
  mounted: function() {
    this.validate();
  },
  computed: {
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.Catelog.get(this.placeholder) + "*";
      }
      return this.Catelog.get(this.placeholder);
    },
    withValue: function() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(this.emit, 500);
    },
    emit: function() {
      clearInterval(this.timer);
      this.$emit('value', {'key': this.title, 'value': ContentUtils.escape(this.value)})
    },
    validate: function() {
      if (this.value != null && this.value.trim().length > 0) {
        this.isTitleShowing = true;
      } else {
        this.isTitleShowing = false;
      }
    },
    enter: function() {
      this.emit();
      this.$emit('enter', this.title);
    }
  },

}
</script>
